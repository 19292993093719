import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private subscriptionKey = environment.translationApiKey;
  private endpoint = environment.translationEndpoint;

  constructor(private http: HttpClient) { }

  translate(text: string, targetLanguage: string): Observable<any> {
    const url = `${this.endpoint}/translator/text/v3.0/translate?api-version=3.0&to=${targetLanguage}`;
    const headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': this.subscriptionKey,
      'Ocp-Apim-Subscription-Region': 'westus3',
      'Content-Type': 'application/json',
      'apitype' : 'translation'
    });
    const body = [{ 'Text': text }];
    return this.http.post<any>(url, body, { headers });
  }

  updateLanguage(code: string) {
    if(code !== 'EN'){
      const elements = document.body.querySelectorAll('*');
      const dropdownArea = document.getElementById('languageDropdown');
      elements.forEach(element => {
        if (element !== dropdownArea && !dropdownArea?.contains(element)) {      
        element.childNodes.forEach(child => {
          if (child.nodeType === Node.TEXT_NODE) {
            const textToTranslate = child.textContent || '';
            this.translate(textToTranslate, code).subscribe(
              (response: any) => {
                if (response && response.length > 0) {
                  child.textContent = response[0].translations[0].text;
                } else {
                  console.error('Unexpected response format:', response);
                }
              },
              (error) => {
                console.error('Error:', error);
              }
            );
          }
        });
      }});
      } 
  }
}
